import PropTypes from "prop-types";
import Link from "next/link";
import ReactPlayer from "react-player";
import {
  ProductPrice,
  ProductTitle,
  ProductWrapper,
  StyledDigitalProductFooter,
  StyledDigitalProductFooterActions,
  StyledDigitalProductFooterText,
} from "./styles";
import { Button } from "../../../../components/LinkItem/styles";
import { httpsUrl, isVideoType } from "../../../../helpers/data_helpers";
import {
  ProductImage,
  ProductVideo,
} from "../../../../components/ShopSwipeBlock/styles";

const DigitalProduct = ({ product, link }) => {
  const { name, media, priceCents } = product;

  const imageUrl = media[0]?.url || "/images/product_default_cover.png";
  const isVideo = isVideoType(imageUrl);
  const mediaHttps = httpsUrl(imageUrl);

  return (
    <Link href={link}>
      <ProductWrapper>
        {isVideo && (
          <ProductVideo>
            <ReactPlayer
              url={mediaHttps}
              playsinline
              autoplay
              playing
              loop
              muted
              width="100%"
              height="100%"
            />
          </ProductVideo>
        )}
        {!isVideo && <ProductImage src={mediaHttps} />}
        <StyledDigitalProductFooter>
          <StyledDigitalProductFooterText>
            <ProductTitle>{name}</ProductTitle>
            <ProductPrice>
              {priceCents ? `$${(priceCents / 100).toFixed(2)}` : "FREE"}
            </ProductPrice>
          </StyledDigitalProductFooterText>
          <StyledDigitalProductFooterActions>
            <Button brand>Buy</Button>
          </StyledDigitalProductFooterActions>
        </StyledDigitalProductFooter>
      </ProductWrapper>
    </Link>
  );
};

DigitalProduct.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    media: PropTypes.arrayOf(PropTypes.shape()),
    priceCents: PropTypes.string,
  }).isRequired,
  link: PropTypes.string.isRequired,
};

export default DigitalProduct;
