import PropTypes from "prop-types";
import ProductList from "./content";
import { PageContextProvider } from "./context";

const ProductsPage = ({ data }) => (
  <PageContextProvider data={data}>
    <ProductList />
  </PageContextProvider>
);

ProductsPage.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default ProductsPage;
