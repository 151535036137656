import { useRouter } from "next/router";
import PropTypes from "prop-types";
import { PRODUCT_TYPES } from "../../../../constants/products";
import DropProduct from "./DropProduct";
import PhysicalProduct from "./PhysicalProduct";
import DigitalProduct from "./DigitalProduct";

const ProductType = ({ product }) => {
  const router = useRouter();
  const { userHandle } = router.query;
  const { shortId, type } = product;
  const link = `/${encodeURIComponent(userHandle)}/p/${encodeURIComponent(
    shortId
  )}`;
  return (
    <>
      {type === PRODUCT_TYPES.DROP && (
        <DropProduct product={product} link={link} />
      )}
      {type === PRODUCT_TYPES.PHYSICAL && (
        <PhysicalProduct product={product} link={link} />
      )}
      {type === PRODUCT_TYPES.DIGITAL && (
        <DigitalProduct product={product} link={link} />
      )}
    </>
  );
};
ProductType.propTypes = {
  product: PropTypes.shape().isRequired,
};
export default ProductType;
