import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import { sortBy } from "lodash";
import {
  ProductPrice,
  ProductTitle,
  StyledDropProductFooter,
  StyledDropProductFooterActions,
  StyledDropProductFooterText,
  StyledDropProductImageWrapper,
  StyledDropProductInfoTitle,
  StyledDropProductStatus,
  StyledDropProductStockAmount,
  StyledDropProductStockAmountItems,
  StyledDropProductStockAmountLabel,
  StyledDropProductTimer,
  StyledDropProductWrapper,
} from "./styles";
import { Button } from "../../../../components/LinkItem/styles";
import DropProductTimer from "./DropProductTimer";
import { DROP_PRODUCT_STATUSES } from "../../../../constants/products";
import Gallery from "../../../../components/Gallery";

const DropProduct = ({ product, link }) => {
  const [stock, setStock] = useState("");
  const {
    name,
    media,
    priceCents,
    dropEndAt,
    dropStartAt,
    dropStatus,
    quantity,
    unlimitedQuantity,
  } = product;

  useEffect(() => {
    if (unlimitedQuantity) {
      setStock("unlimited");
    } else {
      setStock(quantity ? `${quantity} in stock` : "sold out");
    }
  }, [unlimitedQuantity, quantity]);

  return (
    <Link href={link}>
      <StyledDropProductWrapper>
        <StyledDropProductImageWrapper>
          <StyledDropProductInfoTitle>
            {dropStatus === DROP_PRODUCT_STATUSES.LAUNCHING && (
              <StyledDropProductStatus>Launching in:</StyledDropProductStatus>
            )}
            {dropStatus === DROP_PRODUCT_STATUSES.SALING && (
              <StyledDropProductStatus isStarted>
                Sale Ends in:
              </StyledDropProductStatus>
            )}
            {dropStatus === DROP_PRODUCT_STATUSES.ENDED && (
              <StyledDropProductStatus isStarted>
                Sold out
              </StyledDropProductStatus>
            )}
            <StyledDropProductTimer>
              {dropStatus === DROP_PRODUCT_STATUSES.LAUNCHING && (
                <DropProductTimer timeTo={dropStartAt} />
              )}
              {dropStatus === DROP_PRODUCT_STATUSES.SALING && (
                <DropProductTimer timeTo={dropEndAt} />
              )}
              {dropStatus === DROP_PRODUCT_STATUSES.ENDED && <>Finished</>}
            </StyledDropProductTimer>
            <StyledDropProductStockAmount>
              {quantity && !unlimitedQuantity && (
                <StyledDropProductStockAmountLabel>
                  Only
                </StyledDropProductStockAmountLabel>
              )}
              <StyledDropProductStockAmountItems>
                {stock}
              </StyledDropProductStockAmountItems>
            </StyledDropProductStockAmount>
          </StyledDropProductInfoTitle>
          <Gallery images={sortBy(media, "position")} />
        </StyledDropProductImageWrapper>
        <StyledDropProductFooter>
          <StyledDropProductFooterText>
            <ProductTitle>{name}</ProductTitle>
            <ProductPrice>
              {priceCents ? `$${(priceCents / 100).toFixed(2)}` : "FREE"}
            </ProductPrice>
          </StyledDropProductFooterText>
          <StyledDropProductFooterActions>
            {dropStatus === DROP_PRODUCT_STATUSES.LAUNCHING && (
              <Button brand>Notify Me</Button>
            )}
            {dropStatus === DROP_PRODUCT_STATUSES.SALING && (
              <Button brand>Buy</Button>
            )}
            {dropStatus === DROP_PRODUCT_STATUSES.ENDED && (
              <Button brand disabled>
                Sold Out
              </Button>
            )}
          </StyledDropProductFooterActions>
        </StyledDropProductFooter>
      </StyledDropProductWrapper>
    </Link>
  );
};

DropProduct.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    media: PropTypes.arrayOf(PropTypes.shape()),
    priceCents: PropTypes.string,
    quantity: PropTypes.number,
    unlimitedQuantity: PropTypes.bool,
    dropStatus: PropTypes.string,
    dropStartAt: PropTypes.string,
    dropEndAt: PropTypes.string,
  }).isRequired,
  link: PropTypes.string.isRequired,
};

export default DropProduct;
