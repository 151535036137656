import { useContext } from "react";
import Link from "next/link";
import { ContentWrapper } from "../../styles";
import ProductType from "./ProductType";
import { PageContext } from "../context";
import { LPText } from "../styles";

const ProductList = () => {
  const { productItems } = useContext(PageContext);

  return (
    <ContentWrapper>
      <ContentWrapper nopadding>
        {productItems.map((product) => (
          <ProductType key={product.id} product={product} />
        ))}
      </ContentWrapper>

      <LPText tiny centered mtauto>
        By buying one of the products from this page you agree to receive
        messages from me via my Preneur Number. You can opt-out at any time by
        replying <b> STOP</b> to any message you receive from me. Your carrier
        may charge you normal SMS or data rates. You will receive occasional
        promotional offers and updates related to our products and services. For
        more information, please review our &nbsp;
        <Link href="/terms-&-conditions">Terms</Link>
        &nbsp; and &nbsp;
        <Link href="/privacy-policy">Privacy Policy</Link>
      </LPText>
    </ContentWrapper>
  );
};

export default ProductList;
