import PropTypes from "prop-types";
import getClient from "../../../apollo-client";
import {
  GET_PRODUCTS_LIST,
  GET_MICROSITE_ACCOUNT,
} from "../../../network/queries";
import MicrositeLayout from "../../../layouts/MicrositeLayout";
import ProductsPage from "../../../site-pages/ProductPage";

const UserPage = ({ data }) => <ProductsPage data={data} />;

UserPage.Layout = MicrositeLayout;

export const getServerSideProps = async (context) => {
  const client = await getClient();
  let productItems = [];

  const productPromise = new Promise((resolve) => {
    client
      .query({
        query: GET_PRODUCTS_LIST,
        variables: {
          username: context.params.userHandle,
        },
      })
      .then((res) => {
        productItems = res.data.account.availableProducts || [];
        resolve();
      })
      .catch(() => {
        productItems = [];
      });
  });

  const {
    data: { account },
  } = await client.query({
    query: GET_MICROSITE_ACCOUNT,
    variables: {
      username: context.params.userHandle,
    },
  });

  const mode = account?.microsite?.style?.mode;
  await productPromise;

  return {
    props: {
      data: productItems,
      routeParams: context.params,
      pageData: {
        isDark: mode === "DARK",
        hasVpn: account?.hasVpn || null,
        vpn: account?.microsite?.heroSectionContent?.friendlyVpn || null,
        avatarMedia: account?.profile?.avatar?.uri || null,
        title: account?.profile?.name || null,
        textUrl: account?.microsite?.heroSectionContent?.btnUrl || null,
        description: account?.profile?.title || null,
        extraText: account?.bookingLandingPage?.subtitle || null,
        account,
        avatarImg: account?.profile?.avatar?.uri || null,
        coverImg: account?.microsite?.linksPage?.heroSection?.img?.uri || null,
        coverMedia:
          account?.microsite?.schedulePage?.heroSection?.video?.uri || null,
        defaultCoverMedia:
          account?.microsite?.defaultHeroSection?.video?.uri || null,
        defaultCoverImg:
          account?.microsite?.defaultHeroSection?.img?.uri || null,
        showUserInfo: true,
      },
    },
  };
};

UserPage.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default UserPage;
